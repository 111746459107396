@font-face {
  font-family: "Petrona";
  font-display: swap;
  src: local("Petrona"),
    url("./assets/fonts/Petrona-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GreatVibes";
  font-display: swap;
  src: local("GreatVibes"),
    url("./assets/fonts/GreatVibes-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

body {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
  min-height: 100%;
  position: relative;
}

#root {
  min-height: 100%;
  position: relative;
}
