@import "../../../node_modules/bootstrap/scss/functions";
@import "../../assets/scss/_variable.scss";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

//*** arrow button style *** //
.buttonBrown {
  background-color: $darkorange;
  border-color: $darkorange;

  &:focus {
    box-shadow: none;
  }
}

.buttonOrange {
  width: 46px;
}

.button {
  height: 40px;
  font-size: 0.875rem;
  border-radius: 5px;
}

//*** button style *** //
.normalButton {
  border-radius: $borderradius;
  height: 30px;
  font-size: 14px;
  color: $darkorange;
  border-color: $darkorange;

  @include media-breakpoint-down(lg) {
    font-size: 12px;
  }
  &:hover {
    background-color: $darkorange;
    color: white;
  }
}

// *** Footer *** //
.footer {
  background-color: $darkorange;
  height: 60px;
  position: absolute;
  width: 100%;
  bottom: 0;
  @include media-breakpoint-down(sm) {
    position: relative;
  }
  p {
    @include media-breakpoint-down(sm) {
      text-align: center;
      font-size: 12px;
      width: 100%;
    }
  }
}
