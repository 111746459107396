// *** colors *** //
$primary: #6d412a;
$secondary: #fe8147;
$darkorange: #af491c;
$content: #323337;
$placeholder: #60636d;
$labeltext: #5a5554;
$background : rgba(255, 255, 255, 0.25);

// *** font *** //
$fontcontent: Poppins;
$fonttitle: GreatVibes;
$fontsubtitle: Petrona;

// *** border *** //
$borderradius: 10px;

// *** dropdown *** //
$dropdown-font-size: 12px;
$dropdown-border-radius: $borderradius;
$dropdown-box-shadow: 0px 2px 6px 0 rgba(175, 73, 28, 0.25);
$dropdown-link-hover-bg: rgba(175, 73, 28, 0.3);
$dropdown-link-color: $labeltext;
$dropdown-link-hover-color: $labeltext;
$dropdown-link-active-color: $labeltext;
$dropdown-link-active-bg: rgba(175, 73, 28, 0.3);

// *** card *** //
$card-border-radius: $borderradius;
