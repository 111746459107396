@import "../node_modules/bootstrap/scss/functions";

@import "./assets/scss/variable";

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Optional Bootstrap components here
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";

@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/tables";

@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/helpers";

@import "../node_modules/bootstrap/scss/utilities/api";

*{
  margin: 0;
  padding: 0;
}

body {
  background: #faf6f5;
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  scroll-behavior: smooth;
}
